<template>
  <div class="flex w-full justify-between gap-2 rounded p-6">
    <div class="flex gap-2">
      <div>{{ $t("magic_search_two_point.modal.selected_profile") }}:</div>
      <div ref="dropdown" class="relative" @click="toggleDropdown">
        <div v-if="selectedProfile" class="cursor-pointer">
          {{ selectedProfile }}
        </div>
        <div v-else class="cursor-pointer">
          {{ $t("magic_search_two_point.modal.not_selected") }}
        </div>
        <div
          v-if="isOpen"
          :class="[
            'absolute right-0 top-6 shadow-lg',
            'z-10 rounded border border-gray-300 bg-white p-1',
          ]"
        >
          <ul>
            <li
              v-for="item in sortOptions"
              :key="item.value"
              :class="[
                'cursor-pointer rounded px-6 py-1 text-sm',
                selectedProfile === item.value ? 'bg-gray-100' : 'bg-white',
              ]"
              @click="selectOption(item.value)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <BaseButton @click="onProfileClear">{{
      $t("magic_search_two_point.modal.clear_profile")
    }}</BaseButton>
  </div>
</template>

<script>
import {
  getItem,
  setItem,
  profileStorageName,
  profileStorageEventName,
} from "@/utils/storage";
import { mapMutations } from "vuex";
import BaseButton from "@/components/ui/BaseButton.vue";

export default {
  name: "ProfileContent",
  components: { BaseButton },
  data() {
    return {
      selectedProfile: null,
      isOpen: false,
      sortOptions: [
        {
          value: "Tech Tinkerer",
          label: "Tech Tinkerer",
        },
        {
          value: "Nurturing Nostalgist",
          label: "Nurturing Nostalgist",
        },
        {
          value: "Mechanic Aquarist",
          label: "Mechanic Aquarist",
        },
        {
          value: "Urban Maven",
          label: "Urban Maven",
        },
        {
          value: "Beauty Artisan",
          label: "Beauty Artisan",
        },
        {
          value: "Practical Tradesman",
          label: "Practical Tradesman",
        },
        {
          value: "Food Lover",
          label: "Food Lover",
        },
        {
          value: "TogetherAI",
          label: "TogetherAI",
        },
        {
          value: "NewLLMRanker",
          label: "NewLLMRanker",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.selectedProfile = getItem(profileStorageName);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    ...mapMutations("hybridActions", ["SET_PROFILE_MODAL_OPEN"]),
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    changeProfile(option) {
      this.selectedProfile = option;
      setItem(profileStorageName, option);
      this.isOpen = false;
      window.dispatchEvent(
        new CustomEvent(profileStorageEventName, {
          detail: {
            profile: option,
          },
        }),
      );
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.isOpen = false;
      }
    },
    selectOption(option) {
      this.changeProfile(option);
    },
    onProfileClear() {
      this.changeProfile(null);
    },
  },
};
</script>
