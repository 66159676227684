export function searchToQueryParams(search) {
  const q = search?.replace(/^[?#]/, "");
  if (!q) return undefined;

  const p = q ? new URLSearchParams(q) : null;
  return Object.fromEntries(p);
}

export function queryParamsToSearch(queryParams) {
  const p = new URLSearchParams(queryParams);
  return p.toString();
}
