import logger from "@/services/logger";

export function isEmbedded() {
  return isInIframe() && isSameHost();
}

export function isInIframe() {
  return window.self !== window.top;
}

export function getParentHostname() {
  try {
    return window.top.location.hostname;
  } catch (e) {
    logger.error("Error getting parent hostname", { error: e });
    return null;
  }
}

export function isSameHost() {
  return window.location.hostname === getParentHostname();
}

export function isSimilarHost() {
  const parentHostParts = getParentHostname().split(".");
  const currentHostParts = window.location.hostname.split(".");

  if (parentHostParts.length < 2 || currentHostParts.length < 2) {
    return false;
  }

  const p = parentHostParts
    .slice(Math.max(2, parentHostParts.length - 1))
    .join(".");
  const c = currentHostParts
    .slice(Math.max(2, currentHostParts.length - 1))
    .join(".");

  return p.endsWith(c) || c.endsWith(p);
}
