<template>
  <SearchBar />
  <div class="container mx-auto border-solid border-grey-400 p-0 lg:border-x">
    <div class="w-full overflow-x-hidden">
      <div class="flex w-full gap-2">
        <div
          v-if="greaterThanMD"
          class="border-r border-solid border-grey-400 md:w-96"
          :class="
            isInIframe ? 'side-modal-content-framed' : 'side-modal-content'
          "
        >
          <SidePanel class="md:w-96" />
        </div>
        <div
          class="grow overflow-x-auto"
          :class="
            greaterThanMD &&
            (isInIframe ? 'side-modal-content-framed' : 'side-modal-content')
          "
        >
          <MagicHelperError />
          <MagicHelperMain />
          <SearchInstantResults />
          <ShoppingGuide />
          <SearchResults />
        </div>
      </div>
    </div>
    <SearchActions />
    <Transition name="fade" duration="1000">
      <div
        v-if="refineLoading"
        class="fixed inset-0 z-50 animate-pulse bg-black/25 transition-all duration-1000"
      ></div>
    </Transition>
  </div>
</template>

<script>
import logger from "@/services/logger";
import { moveToNewSearch } from "@/store/utils/hybrid";
import { isInIframe } from "@/utils/embedded";
import { filterEntryQueryParams } from "@/utils/entry-point";
import { parseSearchToVueQuery } from "@/utils/location";
import resolveConfig from "tailwindcss/resolveConfig";
import { mapActions, mapMutations, mapState } from "vuex";
import tailwindConfig from "../../../tailwind.config";
import TrackingService from "../../services/TrackingService";
import MagicHelperError from "./helper/MagicHelperError.vue";
import MagicHelperMain from "./helper/MagicHelperMain.vue";
import SearchBar from "./search-bar/SearchBar.vue";
import SearchActions from "./SearchActions.vue";
import SearchInstantResults from "./SearchInstantResults.vue";
import SearchResults from "./SearchResults.vue";
import ShoppingGuide from "./ShoppingGuide.vue";
import SidePanel from "./SidePanel.vue";
import {
  isContextualEntry,
  mapSearchPageRouteQueryParams,
} from "./utils/search-entry";
import { searchToQueryParams } from "@/utils/route";
import { isEqual } from "lodash";

export default {
  name: "SearchPage",
  components: {
    SearchActions,
    SearchBar,
    SearchResults,
    MagicHelperMain,
    MagicHelperError,
    SearchInstantResults,
    ShoppingGuide,
    SidePanel,
  },
  data() {
    return {
      isInIframe: isInIframe(),
    };
  },
  computed: {
    ...mapState("search", ["loading", "queryText", "queryTags", "queryImage"]),
    ...mapState("hybridActions", ["refineLoading"]),
    ...mapState({
      screenWidth: (state) => state.windowSize.windowWidth,
    }),
    fullConfig() {
      return resolveConfig(tailwindConfig);
    },
    greaterThanMD() {
      return this.screenWidth >= parseInt(this.fullConfig.theme.screens.md);
    },
  },
  watch: {
    $route() {
      this._syncFromRoute();
      this._syncParentRoute();
    },
  },
  mounted() {
    TrackingService.trackPage(this.$EVENTS?.HYBRID?.MAGIC_LOADED);
    this._syncFromRoute(true);

    window.addEventListener("message", this._onParentMessage);
  },
  beforeUnmount() {
    window.removeEventListener("message", this._onParentMessage);
  },
  methods: {
    ...mapMutations("search", [
      "SET_QUERY_TEXT",
      "SET_QUERY_TAGS",
      "SET_QUERY_IMAGE",
      "SET_QUERY_PRODUCT_ID",
    ]),
    ...mapActions("search", ["search", "reset"]),
    _syncParentRoute() {
      try {
        const search = window.location.search;
        if (isInIframe()) {
          window.parent.postMessage(
            { type: "magic_search_params_update", search },
            "*",
          );
        }
      } catch (error) {
        logger.error("Error updating parent query params:", error);
      }
    },
    _safeGetParentQueryParams() {
      try {
        return parseSearchToVueQuery(window.parent.location.search);
      } catch (error) {
        logger.error("Error getting parent query params:", error);
        return null;
      }
    },
    _syncFromRoute(mounted = false) {
      const q = mounted
        ? (this._safeGetParentQueryParams() ?? this.$route.query)
        : this.$route.query;
      const params = this._decodeQueryParams(q);
      const isContextual = isContextualEntry(params);

      if (isContextual) {
        this._handleContextualEntry(params);
      } else {
        this._handleSearch(params);
      }
    },
    _handleSearch(params) {
      const q = mapSearchPageRouteQueryParams(params);
      const { queryText, queryTags, queryImage, queryProductId } = q;

      this.SET_QUERY_TEXT(queryText);
      this.SET_QUERY_TAGS(queryTags);
      this.SET_QUERY_IMAGE(queryImage);
      this.SET_QUERY_PRODUCT_ID(queryProductId);

      this.search(q);
    },
    _handleContextualEntry(params) {
      // eslint-disable-next-line no-unused-vars
      const { query, exp, st, rawData, ...rest } = params;

      const filteredParams = filterEntryQueryParams(rest);
      const tags = Object.entries(filteredParams)
        .filter(([key]) => !key.startsWith("_"))
        .map(([key, value]) => `${key}: ${value}`);

      this._handleContextualEntryTracking(params);

      logger.log("_handleContextualEntry", { query, tags, rawData });

      moveToNewSearch({
        newQuery: query,
        newTags: tags ?? [],
        encoded_filters: rawData,
      });
    },
    _handleContextualEntryTracking(params) {
      logger.log("_handleContextualEntryTracking", params);
      // TODO track
    },
    _decodeQueryParams(params) {
      return Object.fromEntries(
        Object.entries(params).map(([key, value]) => [
          key,
          decodeURIComponent(value).replace(/\+/g, " "),
        ]),
      );
    },
    _onParentMessage(event) {
      if (event.data.type === "magic_parent_url_changed") {
        const query = searchToQueryParams(event.data?.query);
        logger.log("_onParentMessage", query);
        if (!query || isEqual(query, this.$route.query)) return;

        logger.log("_onParentMessage replace", query);
        this.$router.replace({ query });
      }
    },
  },
};
</script>
