<template>
  <div class="relative z-0">
    <ProductLinkOverlay
      :product-id="productId"
      :product-url="productUrl"
      :source="source"
      :use-external-link="true"
    />
    <slot></slot>
  </div>
</template>

<script>
import ProductLinkOverlay from "@/components/search/products/ProductLinkOverlay.vue";

export default {
  name: "ShoppingGuideLink",
  components: {
    ProductLinkOverlay,
  },
  props: {
    productId: {
      type: [String, Number],
      required: true,
    },
    productUrl: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      default: undefined,
    },
  },
  computed: {},
};
</script>
