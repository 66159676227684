import LoginPage from "@/components/auth/LoginPage.vue";
import LogoutPage from "@/components/auth/LogoutPage.vue";
import BuyPage from "@/components/BuyPage.vue";
import DownloadAppPage from "@/components/download/DownloadAppPage.vue";
import EmbeddedContainer from "@/components/embedded/EmbeddedContainer.vue";
import Entry from "@/components/entry/Entry.vue";
import MobileEntry from "@/components/entry/MobileEntry.vue";
import FavouritesPage from "@/components/FavouritesPage.vue";
import HomePage from "@/components/HomePage.vue";
import Settings from "@/components/navbar/Settings.vue";
import ProductPage from "@/components/product-page/ProductPage.vue";
import SearchPage from "@/components/search/SearchPage.vue";
import { createRouter, createWebHistory } from "vue-router";

const S = process.env.VUE_APP_STAGE;
const SKIP_TESTS = S === "production" || S === "staging";
const TEST_PATHS = ["/embedded"];

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: { public: true },
  },
  {
    path: "/buy",
    name: "Buy",
    component: BuyPage,
    meta: { public: false },
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: () => import("@/components/policies/PrivacyPolicy.vue"),
  },
  {
    path: "/terms-conditions",
    name: "TermsAndConditions",
    component: () => import("@/components/policies/TermsAndConditions.vue"),
  },
  {
    path: "/cookies",
    name: "CookiesPolicy",
    component: () => import("@/components/policies/CookiesPolicy.vue"),
  },
  {
    path: "/favourites",
    name: "Favourites",
    component: FavouritesPage,
    meta: { public: false },
  },
  {
    path: "/buy/:id",
    name: "Product",
    component: ProductPage,
    meta: { public: false },
  },
  {
    path: "/search",
    name: "Search",
    component: SearchPage,
    meta: { public: false },
  },
  {
    path: "/embedded",
    name: "Embedded",
    component: EmbeddedContainer,
    meta: { public: false },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { public: false },
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutPage,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/download",
    name: "Download",
    component: DownloadAppPage,
  },
  {
    path: "/mobile-entry",
    name: "MobileEntry",
    component: MobileEntry,
  },
  {
    path: "/entry",
    name: "Entry",
    component: Entry,
  },
  // Redirect to home if not found
  { path: "/:pathMatch(.*)*", redirect: "/" },
].filter((r) => (SKIP_TESTS ? !TEST_PATHS.includes(r.path) : true));

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
