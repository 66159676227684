<template>
  <div v-if="shoppingGuideData.featured_product">
    <h3 class="mb-4 text-lg font-semibold">
      {{ $t("magic_search_two_point.modal.shopping_featured_product") }}
    </h3>

    <div class="rounded-xl border p-6 shadow">
      <div class="flex flex-col gap-6">
        <div>
          <BaseImage
            :src="
              parseOlxImageUrl(shoppingGuideData.featured_product.product_image)
            "
            class="mx-auto rounded-lg object-cover"
            :alt="shoppingGuideData.featured_product.title"
          />
        </div>
        <div class="space-y-4">
          <div class="flex items-start justify-between">
            <div>
              <h2 class="text-xl font-bold">
                {{ shoppingGuideData.featured_product.title }}
              </h2>
              <p class="text-sm text-gray-600">
                {{ shoppingGuideData.featured_product.tagline }}
              </p>
              <div class="mt-2 space-y-1">
                <p
                  v-for="feature in shoppingGuideData.featured_product
                    .key_features"
                  :key="'feature_' + feature.textual_description"
                  class="text-sm"
                >
                  • {{ feature.textual_description }}
                </p>
              </div>
            </div>
            <div class="text-right">
              <span class="text-2xl font-bold text-teal-600"
                >{{ shoppingGuideData.featured_product.price }} zł</span
              >
            </div>
          </div>
          <div class="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4">
            <BaseButton
              v-for="feature in shoppingGuideData.featured_product.key_features"
              :key="'query_' + feature.feature"
              v-track:click="{
                ...$EVENTS.HYBRID.MAGIC_SHOPPING_GUIDE_SEARCH_CLICK,
                magic_ad_source: 'featured',
              }"
              variant="tertiary"
              @click="makeSearch(feature.search_query)"
            >
              <LuicideIcon
                v-if="feature.icon"
                :name="feature.icon"
                class="size-3 shrink-0 text-teal-600 sm:size-4"
                :stroke-width="2"
              />
              <span
                class="min-w-0 flex-1 truncate text-center text-xs sm:text-sm"
                :title="feature.feature"
                >{{ feature.feature }}</span
              >
              <SearchIcon class="ml-auto size-3 shrink-0 sm:size-4" />
            </BaseButton>
            <ShoppingGuideLink
              v-if="
                shoppingGuideData.featured_product.product_link &&
                shoppingGuideData.featured_product.product_id
              "
              v-track:click="{
                ...$EVENTS.HYBRID.MAGIC_AD_LINK,
                ad_id: shoppingGuideData.featured_product.product_id,
                magic_ad_source: 'featured',
              }"
              :product-id="shoppingGuideData.featured_product.product_id"
              :product-url="shoppingGuideData.featured_product.product_link"
              source="featured"
              class="w-full"
            >
              <BaseButton variant="primary" class="w-full">
                <ExternalIcon class="size-3 shrink-0 text-white sm:size-4" />
                <span
                  class="min-w-0 flex-1 truncate text-center text-xs sm:text-sm"
                  :title="$t('magic_search_two_point.modal.show_on_olx')"
                >
                  {{ $t("magic_search_two_point.modal.show_on_olx") }}
                </span>
                <ChevronRightIcon class="ml-auto size-3 shrink-0 sm:size-4" />
              </BaseButton>
            </ShoppingGuideLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LuicideIcon from "@/components/ui/LuicideIcon.vue";
import SearchIcon from "@/components/ui/icons/SearchIcon.vue";
import ExternalIcon from "@/components/ui/icons/ExternalIcon.vue";
import ChevronRightIcon from "@/components/ui/icons/ChevronRightIcon.vue";
import BaseButton from "@/components/ui/BaseButton.vue";
import ShoppingGuideLink from "./ShoppingGuideLink.vue";
import BaseImage from "@/components/ui/Image.vue";
import { parseOlxImageUrl } from "@/utils/olx-images";

export default {
  name: "FeaturedProduct",
  components: {
    LuicideIcon,
    SearchIcon,
    ExternalIcon,
    ChevronRightIcon,
    BaseButton,
    ShoppingGuideLink,
    BaseImage,
  },
  data() {
    return {
      parseOlxImageUrl,
    };
  },
  computed: {
    ...mapGetters("guideActions", ["shoppingGuideData"]),
  },
  methods: { ...mapActions("guideActions", ["makeSearch"]) },
};
</script>
