<template>
  <Transition name="slide-left" duration="300">
    <div
      v-if="show"
      :class="
        $twMerge(
          'mx-2 my-1 flex items-center gap-2 transition-all duration-300',
          'bg-white px-2 py-3 overflow-hidden shadow-sm rounded-md',
        )
      "
    >
      <TitleLead class="ml-3 mr-1 inline" />

      <div
        class="relative flex h-4 grow flex-col items-center justify-center gap-2"
      >
        <Transition name="slide-up" duration="300">
          <div
            :key="activeIndex"
            class="absolute w-full py-1 text-sm text-magic-900 transition-all duration-300"
          >
            <strong>{{ message[0] }}</strong>
            {{ message[1] }}
          </div>
        </Transition>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapGetters } from "vuex";
import TitleLead from "@/components/ui/icons/TitleLead.vue";

export default {
  name: "ComplexSearchIndicator",
  components: {
    TitleLead,
  },
  data() {
    return {
      interval: null,
      activeIndex: 0,
      messages: new Array(6)
        .fill("")
        .map((_, i) => this.$t(`search.loading.complex.${i}`)),
    };
  },
  computed: {
    ...mapGetters("search", [
      "isComplexQuery",
      "products",
      "productsLoading",
      "queryBasedGroupsLoading",
    ]),
    show() {
      if (!this.isComplexQuery) return false;
      if ((this.products?.length ?? 0) > 0) return false;
      if (this.productsLoading || this.queryBasedGroupsLoading) return true;
      return false;
    },
    message() {
      const message = this.messages[this.activeIndex];
      const words = message.split(" ");
      return [words[0], words.splice(1).join(" ")];
    },
  },
  watch: {
    show(newVal) {
      if (!newVal) return;
      this.activeIndex = 0;
      this._interval();
    },
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  methods: {
    _interval() {
      if (this.interval) return;
      this.interval = setInterval(() => {
        this.activeIndex = Math.min(
          this.activeIndex + 1,
          this.messages.length - 1,
        );
      }, 1000);
    },
  },
};
</script>
