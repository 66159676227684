<template>
  <!-- Clickable overlay -->
  <template v-if="!isInIframe">
    <a
      v-if="link.includes('://')"
      :href="link"
      class="absolute inset-0 z-[1] cursor-pointer"
    ></a>
    <RouterLink
      v-else
      :to="link"
      class="absolute inset-0 z-[1] cursor-pointer"
    />
  </template>

  <div
    v-else
    class="absolute inset-0 z-[1] cursor-pointer"
    @click="onClickInIFrame"
  ></div>
</template>

<script>
import { isEmbedded, isInIframe } from "@/utils/embedded";
import logger from "@/services/logger";

export default {
  name: "ProductLinkOverlay",
  props: {
    productId: {
      type: [String, Number],
      required: true,
    },
    productUrl: {
      type: String,
      required: true,
    },
    contextualTag: {
      type: String,
      default: undefined,
    },
    source: {
      type: String,
      default: undefined,
    },
    useExternalLink: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isInIframe() {
      return isInIframe();
    },
    isEmbedded() {
      return isEmbedded();
    },
    urlParams() {
      const params = {
        reason: "magic",
        ad_reason: "magic",
        from: "magic_hybrid",
      };
      if (this.contextualTag) params.ad_tag = 1;
      if (this.source) params.ad_source = this.source;
      return params;
    },
    internalLink() {
      const url = `/buy/${this.productId}`;
      return addUrlParams(url, this.urlParams);
    },
    productPath() {
      return addUrlParams(this.productUrl, this.urlParams).replace(
        "https://www.olx.pl",
        "",
      );
    },
    productLink() {
      return addUrlParams(this.productUrl, this.urlParams);
    },
    link() {
      return this.useExternalLink ? this.productLink : this.internalLink;
    },
  },
  methods: {
    onClickInIFrame() {
      try {
        if (this.isEmbedded) {
          logger.log("Clicking in embedded", { productId: this.productId });
          window.parent.location.href = this.link;
        } else if (this.isInIframe) {
          logger.log("Clicking in iframe", { productId: this.productId });
          window.parent.postMessage(
            { type: "magic_ad_clicked", url: this.productPath },
            "*",
          );
        } else {
          logger.error("Unexpected ad click in non-embedded context");
          window.location.href = this.link;
        }
      } catch (e) {
        logger.error("Error clicking in embedded", { error: e });
      }
    },
  },
};

function addUrlParams(url, params) {
  try {
    const isUrl = url.includes("://");
    if (isUrl) {
      const urlObj = new URL(url);
      Object.entries(params).forEach(([key, value]) => {
        urlObj.searchParams.append(key, value);
      });
      return urlObj.toString();
    } else {
      const p = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        p.append(key, value);
      });
      return `${url}?${p.toString()}`;
    }
  } catch (e) {
    logger.error("Error adding URL parameters", { error: e });

    return url;
  }
}
</script>
