<template>
  <div class="static-full-page-content w-screen">
    <iframe ref="iframe" class="size-full" :src="url" />
  </div>
</template>

<script>
import logger from "@/services/logger";
import { isEqual } from "lodash";
import { searchToQueryParams } from "@/utils/route";

export default {
  name: "EmbeddedContainer",
  data() {
    return {
      url: "http://localhost:8080/search",
    };
  },
  watch: {
    $route: {
      handler() {
        this.handleSearchParamsChange();
      },
    },
  },
  mounted() {
    this.url = `http://localhost:8080/search?${window.location.search}`;

    window.addEventListener("message", this.handleMessage);
  },
  beforeUnmount() {
    window.removeEventListener("message", this.handleMessage);
  },
  methods: {
    // Simualates messages comming to olx from magic
    handleMessage(event) {
      if (event.data.type === "magic_ad_clicked") {
        logger.log("magic_ad_clicked", event.data);
        window.location.href = `https://www.olx.pl${event.data.url}`;
      } else if (event.data.type === "magic_search_params_update") {
        const query = searchToQueryParams(event.data.search);
        logger.log("magic_search_params_update", query);
        if (!query || isEqual(query, this.$route.query)) return;

        logger.log("magic_search_params_update replace", query);
        this.$router.replace({ query });
      }
    },
    // Simulates location change messages from olx to magic
    handleSearchParamsChange() {
      this.$refs.iframe.contentWindow.postMessage({
        type: "magic_parent_url_changed",
        query: window.location.search,
      });
    },
  },
};
</script>
